export default [
  {
    path: "/superadmin/benefits-internal",
    children: [
      {
        path: "/superadmin/benefits-internal",
        name: "benefits-internal-index",
        component: () =>
          import("@/views/roles/super-admin/benefits-internal/Index.vue"),
        meta: {
          pageTitle: "Beneficios Internos",
          breadcrumbs: ["Admin", "Beneficios Internos"],
        },
      },
      {
        path: "/superadmin/benefits-internal/create",
        name: "benefits-internal-create",
        component: () =>
          import("@/views/roles/super-admin/benefits-internal/Create.vue"),
        meta: {
          pageTitle: "Crear Beneficio Interno",
          breadcrumbs: ["Admin", "Beneficios Internos", "Crear"],
        },
      },
      {
        path: "/superadmin/benefits-internal/edit/:id?",
        name: "benefits-internal-edit",
        component: () =>
          import("@/views/roles/super-admin/benefits-internal/Edit.vue"),
        meta: {
          pageTitle: "Editar Beneficios Internal",
          breadcrumbs: ["Admin", "Beneficios Internos", "Editar"],
        },
      },
    ],
  },
];
