import ConfigRoutes from "./modules/announcements";
import AreasRoutes from "./modules/areas";
import BenefitsRoutes from "./modules/benefits";
import BenefitsInternalRoutes from "./modules/benefits-internal";
import EventsRoutes from "./modules/events";
import CalendarRoutes from "./modules/calendar";
import EmployeesRoutes from "./modules/employees";
import HelpRoutes from "./modules/help";
import MealsRoutes from "./modules/meals";
import NewsRoutes from "./modules/news";
import PositionsRoutes from "./modules/positions";
import ReleasesRoutes from "./modules/releases";
import ReportsRoutes from "./modules/reports";
import SurveysRoutes from "./modules/surveys";
import TeamBuildingRoutes from "./modules/teambuilding";
import TeamsRoutes from "./modules/teams";
import WorkModalitiesRoutes from "./modules/work-modalities";
import PartnerRoutes from "./modules/partners";
import QuestionsUsers from "./modules/questionsusers";
// import Integrations from "./modules/integrations";
import Subcompanies from "./modules/subcomapnies";
import Calendars from "./modules/calendars";
import Groups from "./modules/groups";
import Offices from "./modules/offices";
import Hr from "./modules/hr";
import Attendances from "./modules/attendances";

export default [
  {
    path: "/superadmin",
    meta: {
      middleware: "auth",
    },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      ...ConfigRoutes,
      ...AreasRoutes,
      ...BenefitsRoutes,
      ...BenefitsInternalRoutes,
      ...EventsRoutes,
      ...CalendarRoutes,
      ...EmployeesRoutes,
      ...HelpRoutes,
      ...MealsRoutes,
      ...NewsRoutes,
      ...PositionsRoutes,
      ...ReleasesRoutes,
      ...ReportsRoutes,
      ...SurveysRoutes,
      ...TeamBuildingRoutes,
      ...TeamsRoutes,
      ...WorkModalitiesRoutes,
      ...PartnerRoutes,
      ...QuestionsUsers,
      // ...Integrations,
      ...Subcompanies,
      ...Calendars,
      ...Groups,
      ...Offices,
      ...Hr,
      ...Attendances,
    ],
  },
];
