export default [
  {
    path: "/employee/benefits-internal",
    children: [
      {
        path: "/employee/benefits-internal",
        name: "benefits-internal-employee-index",
        component: () =>
          import("@/views/roles/employee/benefitsinternal/Index.vue"),
        meta: {
          pageTitle: "Beneficios Internos",
          breadcrumbs: ["Empleados", "Beneficios Internos"],
        },
      },
    ],
  },
];
